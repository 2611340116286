import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DialogActions, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import DialogComponent from '../../../inputs/DailogComponent';
import ButtonComponent from '../../../inputs/ButtonCompnent';
import { Form, Formik } from 'formik';
import AddressSection from './AddressSection';
import { customerUpdateApi } from '../../../services/Redux/Reducer/LoginSlice';
import withRouter from '../../../services/HOC/withRouterHOC';
import parse from 'html-react-parser';
import { storeAssigned } from '../../../services/Methods/normalMethods';

const AddessOptionStyle = styled.div`
    &.mainAddress_option {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        width: 100%;

        .address_line {
            width: 100%;
            .MuiFormControl-root {
                width: 100%;
            }
            .addAddress_class {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid;
                margin-bottom: 10px;
                padding:0 4px;
                p {
                    margin-bottom: 4px;
                    font-weight: 600;
                    &:first-child {
                        color: #000;
                        font-size: 22px;
                    }
                    &:last-child {
                        font-weight: 600;
                        cursor: pointer;
                        color: #2a533f;
                    }
                }
            }
            .radio_section {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 4px;
                padding: 8px 13px 8px 0px;

                .MuiRadio-root {
                    padding:9px !important;
                }

                @media only screen and (max-width: 767px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                &.activeAddress{
                    background: #2a533f1a;
                    border-radius: 5px;
                }
                label {
                    margin: 0; 

                    @media only screen and (max-width: 767px) {
                        align-items: flex-start;
                    }

                    .MuiButtonBase-root {
                        @media only screen and (max-width: 767px) {
                            padding: 0 9px;
                        }   
                    }

                    p {
                        margin-bottom: 0;
                        strong {
                            font-size: 18px;
                        }
                    }
                }
                .editIcon {
                    color: #2a533f;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 14px;
                    &:hover {
                        border-bottom: 1px solid #2a533f;
                    }
                }
                .DelIcon {
                    color: #f00;
                    &:hover {
                        border-bottom: 1px solid #f00;
                    }
                }

                &>div {
                    @media only screen and (max-width: 767px) {
                        width: calc(100% - 40px);
                        margin-left: 40px;
                    }
                }
            }
        }
    }

`;

const AddressOptions = ({
    alluserData,
    _setUseDefaultAddressFunction,
    userData,
    setUserData,
    setDefaultValues,
    VALIDATION_SCHEMA,
    dispatch,
    section, sameAsBillingChecked, _paymentOptionSetBilling, openDialog, setOpenDialog, error,
    ...props
}) => {
    const [addressObject, setAddressesObject] = useState({ oldData: {}, shippingAddress: {}, addresses: [] });
    // const [openDialog, setOpenDialog] = useState(null);

    function userDataPayload(data) {
        return ({
            "shipping_urname": alluserData?.firstName,
            "shipping_lastname": alluserData?.lastName,
            "shipping_email": alluserData?.emailAddress,
            "shipping_phone": alluserData?.phoneNumbers?.cellPhone,
            "shipping_street": data?.address1,
            "shipping_street2": data?.address2,
            "shipping_city": data?.city,
            "shipping_zipcode": data?.postalCode,
            "shipping_country": data?.countryCode,
            "shipping_state": data?.regionProvState
        })
    }

    function _addressPayload(values) {
        return ({
            // firstName: values?.shipping_urname || '',
            // lastName: values?.shipping_lastname || '',
            address1: values?.shipping_street || '',
            "address2": values?.shipping_street2 || "",
            "address3": "",
            city: values?.shipping_city || "",
            regionProvState: values?.shipping_state || "",
            postalCode: values?.shipping_zipcode || "",
            countryCode: values?.shipping_country || ""
        })
    }

    useEffect(() => {
        if (alluserData?.emailAddress) {
            const PICK_ADDRESSES = _.pick(alluserData, ['shippingAddress', 'billingAddress', 'mailingAddress', 'otherAddress1', 'otherAddress2']);
            const ADDRESSES = _.compact(_.map(_.values(PICK_ADDRESSES), (val, index) => {
                const SECTION = _.keys(PICK_ADDRESSES);
                if (SECTION[index] !== "shippingAddress") {
                    const { address1, city, postalCode } = PICK_ADDRESSES?.shippingAddress;
                    if (val?.address1 !== address1) {
                        return ({
                            id: index + 1,
                            firstName: alluserData?.firstName,
                            lastName: alluserData?.lastName,
                            section: SECTION[index], ...val
                        });
                    }
                } else {
                    return ({
                        id: index + 1,
                        firstName: alluserData?.firstName,
                        lastName: alluserData?.lastName,
                        section: SECTION[index], ...val
                    });
                }
            }));
            const FilterAddresAccStore = _.filter(ADDRESSES, (r) => r?.countryCode === _.upperCase(storeAssigned()));
            setAddressesObject({
                oldData: alluserData,
                shippingAddress: _.find(FilterAddresAccStore, (r) => r?.address1 !== ''),
                addresses: ADDRESSES
            });
            setUserData(userDataPayload(_.find(FilterAddresAccStore, (r) => r?.address1 !== '')));
            _setUseDefaultAddressFunction(null, null, userDataPayload(_.find(FilterAddresAccStore, (r) => r?.address1 !== '')))
            if (section !== 'autoship_edit') {
                setDefaultValues((prv) => ({ ...prv, ...PICK_ADDRESSES }));
            }
        }
    }, [alluserData?.emailAddress]);

    function _selectedDefaulAddress(selectAddress) {
        setAddressesObject((pre) => ({ ...pre, shippingAddress: selectAddress }));
        let CONVERTED_ADDRESS = userDataPayload(selectAddress);
        setUserData(CONVERTED_ADDRESS);
        _setUseDefaultAddressFunction(null, null, CONVERTED_ADDRESS);
        if (sameAsBillingChecked) {
            _paymentOptionSetBilling(CONVERTED_ADDRESS);
        }
    };

    function _handleOpenDialog(row, section) {
        if (!row && section === 'add') {
            setUserData({ ...userData, shipping_city: "", shipping_phone: "", shipping_street: "", shipping_zipcode: "" });
            const SELECT_ROW = _.find(addressObject?.addresses, (row) => !row?.address1);
            setOpenDialog(SELECT_ROW?.id);
        } else {
            setOpenDialog(row?.id);
            const BILLING_DETAIL = userDataPayload(_.find(addressObject?.addresses, { id: row?.id }));
            setUserData(BILLING_DETAIL);
        }
    }

    function _handleCloseFunction() {
        setOpenDialog(null);
        // setUserData({ ...userData, shipping_city: "", shipping_phone: "", shipping_street: "", shipping_street2: "", shipping_zipcode: "" });
    }

    function _deleteAddress(id) {
        const AFTER_DELTED_DATA = _.reject(addressObject?.addresses, { id: id });
        const PAYLOAD = _addressPayload();
        dispatch(customerUpdateApi({
            ...addressObject?.oldData, [_.find(addressObject?.addresses, { id })?.section]: _addressPayload()
        }, 'addressChangeCheckout', alluserData?.customerId, () => {
            setAddressesObject((prv) => ({
                oldData: { ...prv?.oldData, [_.find(prv?.addresses, { id })?.section]: _addressPayload() },
                shippingAddress: (prv?.shippingAddress?.id === id) ? AFTER_DELTED_DATA[0] : prv?.shippingAddress,
                addresses: [...AFTER_DELTED_DATA, { id, ...PAYLOAD }]
            }));
            const PICK_ADDRESSES = _.pick({ ...addressObject?.oldData, [_.find(addressObject?.addresses, { id })?.section]: _addressPayload() }, ['shippingAddress', 'billingAddress', 'mailingAddress', 'otherAddress1', 'otherAddress2']);
            if (section !== 'autoship_edit') {
                setDefaultValues((prv) => ({ ...prv, ...PICK_ADDRESSES }));
            }
            setUserData((addressObject?.shippingAddress?.id === id) ? userDataPayload(AFTER_DELTED_DATA[0]) : userDataPayload(addressObject?.shippingAddress));
        }));
    }

    function _submitFunctionPop(values) {
        const PAYLOAD = _addressPayload(values);
        dispatch(customerUpdateApi({
            ...addressObject?.oldData,
            [_.find(addressObject?.addresses, { id: +openDialog })?.section]: PAYLOAD
        }, 'addressChangeCheckout', alluserData?.customerId, () => {
            setAddressesObject((prv) => ({
                oldData: { ...prv?.oldData, [_.find(prv?.addresses, { id: +openDialog })?.section]: PAYLOAD },
                shippingAddress: { id: +openDialog, ...PAYLOAD },
                addresses: [..._.reject(addressObject?.addresses, { id: openDialog }), { id: +openDialog, ...{ firstName: values?.shipping_urname, lastName: values?.shipping_lastname, ...PAYLOAD } }]
            }));
            const PICK_ADDRESSES = _.pick({ ...addressObject?.oldData, [_.find(addressObject?.addresses, { id: +openDialog })?.section]: PAYLOAD }, ['shippingAddress', 'billingAddress', 'mailingAddress', 'otherAddress1', 'otherAddress2']);
            if (section !== 'autoship_edit') {
                setDefaultValues((prv) => ({ ...prv, ...PICK_ADDRESSES }));
            }
            setUserData(values);
            if (sameAsBillingChecked) {
                _paymentOptionSetBilling(values);
            }
            setOpenDialog(null);
        }));

    }

    return (
        <AddessOptionStyle className='mainAddress_option'>
            <div className='address_line'>
                <div className='addAddress_class'>
                    <p>Your addresses</p>
                    {_.find(addressObject?.addresses, (row) => !row?.address1) &&
                        <p onClick={() => _handleOpenDialog(null, 'add')}>Add a new address</p>
                    }
                </div>
                <FormControl>
                    <RadioGroup value={String(addressObject?.shippingAddress?.id)} className="radio_main_group" name="radio-buttons-group">
                        {_.map(_.filter(addressObject?.addresses, (r) => r?.address1), (row) => (
                            <React.Fragment key={'address' + row?.id}>
                                {(row?.address1) &&
                                    <div className={classNames('radio_section', { 'activeAddress': (addressObject?.shippingAddress?.id === row?.id) })}>
                                        <FormControlLabel onChange={() => _selectedDefaulAddress(row)} value={String(row?.id)} control={<Radio />} label={parse(`
                                            <p>
                                                <strong>${row?.firstName}&nbsp;${row?.lastName}</strong>
                                                <span>${row?.address1}</span>,
                                                <span> ${row?.address2 ? row?.address2 + ',' : ""}</span>
                                                <span>${row?.city}, ${row?.regionProvState}</span>
                                                <span>${row?.postalCode}, ${row?.countryCode}</span>
                                            </p>
                                            `)} />
                                        <div >
                                            <span className='editIcon' onClick={() => _handleOpenDialog(row, 'edit')}>Edit address</span>
                                            &nbsp;|&nbsp;
                                            <span className=' editIcon DelIcon' onClick={() => _deleteAddress(+row?.id)} >Delete</span>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        ))}
                    </RadioGroup>
                </FormControl>
                {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <DialogComponent opend={!!openDialog} handleClose={() => _handleCloseFunction()} title={(!userData?.shipping_city ? "Add" : "Edit") + ' ' + 'Address'} classFor="addessForm">
                <Formik
                    enableReinitialize
                    validationSchema={VALIDATION_SCHEMA}
                    initialValues={userData}
                    onSubmit={(values) => _submitFunctionPop(values)}>
                    {({ setFieldValue, values, errors, handleSubmit, handleChange, handleBlur }) => {
                        const FieldMethod = { values, setFieldValue, errors, handleBlur, handleChange }
                        return (
                            <Form>
                                <AddressSection disabled={false} section="shipping" {...{ ...props, ...FieldMethod }} />
                                <DialogActions className='button_action'>
                                    <ButtonComponent type='submit' disabled={!!props?.normalAddressError}>Submit</ButtonComponent>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </DialogComponent>
        </AddessOptionStyle>
    );
}

export default withRouter(AddressOptions);